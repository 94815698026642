import React from "react";

const Logo = () => {
  return (
    <>
      <img src="LGTM_logo.png" alt="" className="w-72 h-36 my-10" />
    </>
  );
};

export default Logo;
